// 导入axios实例
import http from '@/utils/request.js'

// 注册登录
export function userLogin(data) {
    return http({
        url: '/login/login',
        method:'post',
        data:data
    })
}

// 众筹列表
export function getHomeData() {
    return http({
        url: '/home/getHomeData',
        method:'post',
        data:{
            "warehouseId":0 //仓库id，全部：0，其他：按返回的gameList渲染
        }
    })
}

// 输入密码
export function setPassword(data) {
    return http({
        url: '/login/setPassword',
        method:'post',
        data:data
    })
}

// 获取最新一条公告
export function getNotice() {
    return http({
        url: '/contentManagement/getNotice',
        method:'post',
        data:{
            langue:sessionStorage.getItem('lange')
        }
    })
}

// 取消复投
export function setCancelReinvestment(data) {
    return http({
        url: '/home/setCancelReinvestment',
        method:'post',
        data:data
    })
}

// 用户资产
export function getUserAssets() {
    return http({
        url: '/assetsCenter/getUserAssets',
        method:'post',
        data:{}
    })
}

// 预约订单列表
export function getReservationOrderList(data) {
    return http({
        url: '/reservation/getReservationOrderList',
        method:'post',
        data
    })
}

// 预约
export function setReservationOrder(data) {
    return http({
        url: '/reservation/setReservationOrder',
        method:'post',
        data:data
    })
}
// 获取用户聚合订单利息预收益
export function getUserOrderInterest(data) {
    return http({
        url: '/assetsCenter/getUserOrderInterest',
        method:'post',
        data:data
    })
}

// 奖励明细
export function getUserRewardDetail(data) {
    return http({
        url: '/assetsCenter/getUserRewardDetail',
        method:'post',
        data:data
    })
}

// 获取stt_usdt交易对价格
export function getSttUsdtPrice() {
    return http({
        url: '/assetsCenter/getSttUsdtPrice',
        method:'get',
    })
}
// 用户提现
export function setWithdraw(data) {
    return http({
        url: '/assetsCenter/setWithdraw',
        method:'post',
        data:data
    })
}

// 获取充值信息
export function getUserRechargeInfo() {
    return http({
        url: '/assetsCenter/getRechargeInfo',
        method:'get',
    })
}

// 资产明细
export function getUserAssetsDetail(data) {
    return http({
        url: '/assetsCenter/getUserAssetsDetail',
        method:'post',
        data:data
    })
}

// 我的团队
export function getUserTeamInfo(data) {
    return http({
        url: '/userCenter/getUserTeamInfo',
        method:'post',
        data:data
    })
}

//获取用户信息
export function getUserInfo() {
    return http({
        url: '/userCenter/getUserInfo',
        method:'post',
        data:{}
    })
}

// 公告列表
export function getNoticeList() {
    return http({
        url: '/contentManagement/getNoticeList',
        method:'post',
        data:{}
    })
}

// 预约订单详情
export function getReservationOrderDetail(data) {
    return http({
        url: '/reservation/getReservationOrderDetail',
        method:'post',
        data,
    })
}

// 获取logo

export function getProjectIcon() {
    return http({
        url: '/home/getProjectIcon',
        method:'get',
    })
}

// 获取可取消复投聚合订单
export function getCouldCancel(data) {
    return http({
        url: '/home/getCouldCancel',
        method:'post',
        data,
    })
}

// 修改结算类型
export function setTakeOutType(data) {
    return http({
        url: '/userCenter/setTakeOutType',
        method:'post',
        data,
    })
}

// k线图
export function kline(data) {
    return http({
        url: '/sec/kline',
        method:'post',
        data
    })
}

//获取sec价格
export function secPrice() {
    return http({
        url: '/sec/sec/price',
        method:'get',
    })
}

// 获取sec钱包余额
export function secBalance() {
    return http({
        url: '/sec/sec/balance',
        method:'get',
    })
}

// 获取sec钱包日志
export function secLog(data) {
    return http({
        url: '/sec/wallet/log',
        method:'post',
        data
    })
}

// 兑出usdt
export function exchangeUsdt(data) {
    return http({
        url: '/sec/exchange/usdt',
        method:'post',
        data
    })
}

// sec转账
export function transferSec(data) {
    return http({
        url: '/sec/transfer',
        method:'post',
        data
    })
}
