import axios from 'axios'
import { baseApi } from '../../base_config.json'
import router from '../router'
import {Toast} from "vant";


// 创建一个 axios 实例
const service = axios.create({
  baseURL: baseApi, // 所有的请求地址前缀部分
  timeout: 60000, // 请求超时时间毫秒
  // withCredentials: true, // 异步请求携带cookie
  headers: {
    // 设置后端需要的传参类型
    'Content-Type': 'application/json',
  },
})

// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
        const token = sessionStorage.getItem('token')
        const address = sessionStorage.getItem('address')
        if (token) {
            // 请求头携带token
            config.headers.token = token
            config.headers.address = address

        }
        config.headers.langue =sessionStorage.getItem('lange')

        // 在发送请求之前做些什么
      return config
    },
    function (error) {
      // 对请求错误做些什么
      console.log(error)
      return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
      // 2xx 范围内的状态码都会触发该函数。
      // 对响应数据做点什么
      // dataAxios 是 axios 返回数据中的 data
      // const dataAxios = response.data
      // 这个状态码是和后端约定的
      // const code = dataAxios.reset
        // 系统维护

        if(response.data.code==='350'){
            router.push('/Maintenance')
        }
        // 重复登录

        if(response.data.code==='512' ||response.data.code==='311'){
            // sessionStorage.removeItem('token')
            // sessionStorage.removeItem('address')
            // sessionStorage.removeItem('userId')
            // sessionStorage.setItem('activeTab',0)
            Toast(response.data.msg)
            router.push('/')
        }
        if (response.data.status==='200'){
            return  response.data
        }
        if (response.data.status==='500'){
            // Toast(response.data.message);
            return Promise.reject(response.data)
        }

        //报错提示
        return response.data
    },
    function (error) {
      // 超出 2xx 范围的状态码都会触发该函数。
      // 对响应错误做点什么
      console.log(error)
      return Promise.reject(error)
    }
)

export default service

